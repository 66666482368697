import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { List } from 'immutable'

import ItemValue from './ItemValue';
import LineItemMenu from './LineItemMenu';
import Balance from './Balance';

import {
  forecastToDaily,
  itemDailyValueCents,
  dailyToForecast,
  dailyDollars,
  weeklyDollars,
  monthlyDollars,
  yearlyDollars,
  recurringPaycheckValue,
} from '../util'

class LineItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.item.get('name'),
      amountCents: props.item.get('amountCents'),
      forecastValue: props.item.get('forecastValue'),
      forecastType: props.item.get('forecastType'),
      sortIndex: props.item.get('sortIndex'),
      balanceCents: props.item.get('balanceCents'),
      isShowingChildren: false,
    };

    this.updateItem = this.updateItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.updateTargetDate = this.updateTargetDate.bind(this);
    this.switchType = this.switchType.bind(this);
    this.addSubItem = this.addSubItem.bind(this);
    this.toggleChildren = this.toggleChildren.bind(this);
    this.showChildren = this.showChildren.bind(this);
  }

  render() {
    const subLineItems = this.props.subLineItems.map((item) => {
      return (
        <LineItem
          key={item.get('id')}
          item={item}
          subLineItems={List()}
          incomeRecurrence={this.props.incomeRecurrence}
          isChild={true}

          updateItem={
            this.props.updateItem
          }
          removeItem={
            this.props.removeItem
          }
        />
      )
    })

    const addLineItemButton = (
      <div className="columns is-gapless mb-0">
        <div className="column is-narrow">
          <span className="icon"></span>
        </div>

        <div className="column is-3 mr-3">
          <div className="field is-horizontal">
            <span className="has-text-primary mr-3 pt-1">
            </span>

            <div className="field-body">
              <div className="field">
                <div className="control">
                  <span
                    className="icon has-text-primary is-clickable"
                    onClick={this.addSubItem}
                  >
                    <i className="fas fa-plus-circle"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="column"></div>
        <div className="column"></div>
        <div className="column"></div>
        <div className="column"></div>
        <div className="column"></div>
        <div className="column"></div>

        <div className="column ml-3"></div>
        <div className="column ml-3"></div>

        <div className="column is-narrow">
          <span className="icon"></span>
        </div>

        <div className="column is-narrow">
        </div>
      </div>
    )


    const subLineItemView = (
      <>
        {subLineItems}
        {addLineItemButton}
      </>
    )

    return (
      <>
        <div className="columns is-gapless mb-0">
          {this.itemFields()}
        </div>

        {
          this.state.isShowingChildren &&
          subLineItemView
        }
      </>
    )
  }

  itemFields() {
    const item = this.props.item
    const hasChildren = this.props.subLineItems.size > 0

    const balanceDollars = (this.state.balanceCents / 100).toFixed(2)
    const dailyValueCents = itemDailyValueCents(
      this.state.forecastType,
      this.state.forecastValue,
      this.state.amountCents,
      this.state.balanceCents,
      item.get('paychecksUntilTargetDate'),
      this.props.incomeRecurrence,
      this.props.subLineItems,
      this.props.item.get('parentLineItemId'),
    )

    /////////////////
    // CHILDREN CASE
    /////////////////

    if (hasChildren) {
      const paycheckCents = dailyToForecast(dailyValueCents, this.props.incomeRecurrence)
      const paycheckDollars = (paycheckCents / 100).toFixed(2)
      const childrenIcon = this.state.isShowingChildren ? "fas fa-chevron-up" : "fas fa-chevron-down"

      return (
        <>
          <div className="column is-narrow">
            <span className="icon pt-1">
              <span className="has-text-primary" {...this.props.dragHandleProps} >
                <i className="fas fa-grip-vertical"></i>
              </span>
            </span>
          </div>

          <div className="column is-3 mr-3">
            <input
              className="input is-small"
              aria-label="line-item-name-input"
              type="text"
              value={this.state.name}
              onChange={(e) => { this.updateName(e) }}
            />
          </div>

          <div className="column">
            {/* target date */}
          </div>

          <div className="column">
            {/* target amount */}
          </div>

          <div className="column">
            <input
              className="input is-small has-text-right"
              aria-label={`line-item-yearlyDollars-input`}
              type="text"
              value={yearlyDollars(dailyValueCents)}
              disabled={true}
            />
          </div>

          <div className="column">
            <input
              className="input is-small has-text-right"
              aria-label={`line-item-monthlyDollars-input`}
              type="text"
              value={monthlyDollars(dailyValueCents)}
              disabled={true}
            />
          </div>

          <div className="column">
            <input
              className="input is-small has-text-right"
              aria-label={`line-item-weeklyDollars-input`}
              type="text"
              value={weeklyDollars(dailyValueCents)}
              disabled={true}
            />
          </div>

          <div className="column">
            <input
              className="input is-small has-text-right"
              aria-label={`line-item-dailyDollars-input`}
              type="text"
              value={dailyDollars(dailyValueCents)}
              disabled={true}
            />
          </div>

          <div className="column ml-3">
            <input
              className="input is-small has-text-right"
              aria-label={`line-item-paycheckDollars-input`}
              type="text"
              value={paycheckDollars}
              disabled={true}
            />
          </div>

          <div className="column ml-3">
            <Balance value={balanceDollars} updateItem={this.updateItem} />
          </div>

          <div className="column is-narrow">
            <span
              className="icon has-text-primary is-clickable"
              onClick={this.toggleChildren}
            >
              <i className={childrenIcon}></i>
            </span>
          </div>

          <div className="column is-narrow">
            <LineItemMenu
              forecastType={this.state.forecastType}
              switchType={this.switchType}
              removeItem={this.removeItem}
              addItem={this.addSubItem}
              hasChildren={hasChildren}
              isChild={this.props.isChild}
            />
          </div>
        </>
      )
    }

    //////////////////
    // RECURRING CASE
    //////////////////

    if (this.state.forecastType === "recurring") {
      const inputDailyValueCents = forecastToDaily(this.state.amountCents, this.state.forecastValue)
      const paycheckInput = recurringPaycheckValue(inputDailyValueCents, this.props.incomeRecurrence)

      return (
        <>
          <div className="column is-narrow">
            <span className="icon pt-1">
              {
                !this.props.isChild &&
                <span className="has-text-primary" {...this.props.dragHandleProps} >
                  <i className="fas fa-grip-vertical"></i>
                </span>
              }
            </span>
          </div>

          <div className="column is-3 mr-3">
            <div className="field is-horizontal">
              {
                this.props.isChild &&
                <span className="mr-3 pt-1">
                </span>
              }

              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input
                      className="input is-small"
                      aria-label="line-item-name-input"
                      type="text"
                      value={this.state.name}
                      onChange={(e) => { this.updateName(e) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="column"></div>
          <div className="column"></div>

          <div className="column">
            <ItemValue value={yearlyDollars(inputDailyValueCents)} attribute={"yearly"} updateItem={this.updateItem} />
          </div>

          <div className="column">
            <ItemValue value={monthlyDollars(inputDailyValueCents)} attribute={"monthly"} updateItem={this.updateItem} />
          </div>

          <div className="column">
            <ItemValue value={weeklyDollars(inputDailyValueCents)} attribute={"weekly"} updateItem={this.updateItem} />
          </div>

          <div className="column">
            <ItemValue value={dailyDollars(inputDailyValueCents)} attribute={"daily"} updateItem={this.updateItem} />
          </div>

          <div className="column ml-3">
            <ItemValue value={paycheckInput["value"]} attribute={paycheckInput["attribute"]} updateItem={this.updateItem} />
          </div>

          <div className="column ml-3">
            {
              !this.props.isChild &&
              <Balance value={balanceDollars} updateItem={this.updateItem} />
            }
          </div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-narrow">
            <LineItemMenu
              forecastType={this.state.forecastType}
              switchType={this.switchType}
              removeItem={this.removeItem}
              addItem={this.addSubItem}
              hasChildren={hasChildren}
              isChild={this.props.isChild}
            />
          </div>
        </>
      )
    }


    ////////////////////
    // TARGET DATE CASE
    ////////////////////

    if (this.state.forecastType === "target_date") {
      const targetAmountDollars = (this.state.amountCents / 100).toFixed(2)
      const paycheckCents = dailyToForecast(dailyValueCents, this.props.incomeRecurrence)
      const paycheckDollars = (paycheckCents / 100).toFixed(2)

      return (
        <>
          <div className="column is-narrow">
            <span className="icon pt-1">
              {
                !this.props.isChild &&
                <span className="has-text-primary" {...this.props.dragHandleProps} >
                  <i className="fas fa-grip-vertical"></i>
                </span>
              }
            </span>
          </div>

          <div className="column is-3 mr-3">
            <div className="field is-horizontal">
              {
                this.props.isChild &&
                <span className="mr-3 pt-1">
                </span>
              }

              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input
                      className="input is-small"
                      aria-label="line-item-name-input"
                      type="text"
                      value={this.state.name}
                      onChange={(e) => { this.updateName(e) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <DatePicker
              selected={DateTime.fromISO(this.state.forecastValue).toJSDate()}
              onChange={date => this.updateTargetDate(date)}
              className="input is-small"
              minDate={DateTime.local().toJSDate()}
            />
          </div>

          <div className="column">
            <ItemValue value={targetAmountDollars} attribute={"targetAmount"} updateItem={this.updateItem} />
          </div>

          {
            this.props.isChild ?
            <>
                <div className="column"></div>
                <div className="column"></div>
                <div className="column"></div>
                <div className="column"></div>
            </>
            :
            <>
              <div className="column">
                <input
                  className="input is-small has-text-right"
                  aria-label={`line-item-yearlyDollars-input`}
                  type="text"
                  value={yearlyDollars(dailyValueCents)}
                  disabled={true}
                />
              </div>

              <div className="column">
                <input
                  className="input is-small has-text-right"
                  aria-label={`line-item-monthlyDollars-input`}
                  type="text"
                  value={monthlyDollars(dailyValueCents)}
                  disabled={true}
                />
              </div>

              <div className="column">
                <input
                  className="input is-small has-text-right"
                  aria-label={`line-item-weeklyDollars-input`}
                  type="text"
                  value={weeklyDollars(dailyValueCents)}
                  disabled={true}
                />
              </div>

              <div className="column">
                <input
                  className="input is-small has-text-right"
                  aria-label={`line-item-dailyDollars-input`}
                  type="text"
                  value={dailyDollars(dailyValueCents)}
                  disabled={true}
                />
              </div>
            </>
          }


          <div className="column ml-3">
            {
              !this.props.isChild &&
              <input
                className="input is-small has-text-right"
                aria-label={`line-item-paycheckDollars-input`}
                type="text"
                value={paycheckDollars}
                disabled={true}
              />
            }
          </div>

          <div className="column ml-3">
            {
              !this.props.isChild &&
              <Balance value={balanceDollars} updateItem={this.updateItem} />
            }
          </div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-narrow">
            <LineItemMenu
              forecastType={this.state.forecastType}
              switchType={this.switchType}
              removeItem={this.removeItem}
              addItem={this.addSubItem}
              hasChildren={hasChildren}
              isChild={this.props.isChild}
            />
          </div>
        </>
      )
    }
  }

  // TODO feel like these could all be condensed somehow
  updateName(event) {
    const newName = event.target.value

    this.setState({
      name: newName,
    });

    this.props.updateItem(
      this.props.item.get('id'),
      newName,
      this.state.amountCents,
      this.state.forecastValue,
      this.state.balanceCents,
    )
  }

  switchType() {
    if (this.state.forecastType === "recurring") {
      const today = DateTime.local().toISODate()

      this.setState({
        amountCents: 0,
        forecastValue: today,
        forecastType: "target_date"
      });

      this.props.updateItem(
        this.props.item.get('id'),
        this.state.name,
        0, // amountCents
        today, // forecastValue
        this.state.balanceCents,
      )
    } else {
      this.setState({
        amountCents: 0,
        forecastValue: "monthly",
        forecastType: "recurring"
      });

      this.props.updateItem(
        this.props.item.get('id'),
        this.state.name,
        0, // amountCents
        "monthly", // forecastValue
        this.state.balanceCents,
      )
    }
  }

  updateTargetDate(date) {
    const newDate = DateTime.fromJSDate(date)
    const newDateISO = newDate.toISODate()

    this.setState({
      forecastValue: newDateISO,
    });

    this.props.updateItem(
      this.props.item.get('id'),
      this.state.name,
      this.state.amountCents,
      newDateISO, // forecastValue
      this.state.balanceCents,
    )
  }

  // TODO meh
  updateItem(attribute, value) {
    if (attribute === 'balance') {
      const newBalanceCents = value

      this.setState({
        balanceCents: newBalanceCents,
      });

      this.props.updateItem(
        this.props.item.get('id'),
        this.state.name,
        this.state.amountCents,
        this.state.forecastValue,
        newBalanceCents
      )

    } else if (attribute === 'targetAmount') {
      const targetAmountCents = value

      this.setState({
        amountCents: targetAmountCents,
      });

      this.props.updateItem(
        this.props.item.get('id'),
        this.state.name,
        targetAmountCents, // amountCents
        this.state.forecastValue,
        this.state.balanceCents
      )
    } else { // editing recurring amounts
      this.setState({
        amountCents: value,
        forecastValue: attribute,
      });

      this.props.updateItem(
        this.props.item.get('id'),
        this.state.name,
        value, // amountCents
        attribute, // forecastValue
        this.state.balanceCents
      )
    }
  }

  removeItem() {
    this.props.removeItem(
      this.props.item.get('id'),
      this.props.item.get('parentLineItemId')
    )
  }

  addSubItem() {
    this.showChildren()

    this.props.addItem(
      this.props.item.get('id')
    )
  }

  toggleChildren() {
    this.setState({
      isShowingChildren: !this.state.isShowingChildren
    })
  }

  showChildren() {
    this.setState({
      isShowingChildren: true
    })
  }
}

export default LineItem;