import { Map } from 'immutable'

import {
  RECEIVE_ACCOUNT,
  RECEIVE_UPDATED_SETTINGS,
  RECEIVE_UPDATED_INCOME,
} from './actions'

const initialState = Map({
  email: "",
  settings: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ACCOUNT: {
      const email = action.account.email
      const settings = action.account.settings
      const income = action.account.income

      const formattedIncome = {
        amountCents: income.amount_cents,
        recurrence: income.recurrence,
        startDate: income.start_date,
      }

      return state
        .set('email', email)
        .set('settings', settings)
        .set('income', formattedIncome)
    }

    case RECEIVE_UPDATED_SETTINGS: {
      const settings = action.settings

      return state.set('settings', settings)
    }

    case RECEIVE_UPDATED_INCOME: {
      const income = action.income

      return state
        .setIn(['income', 'amountCents'], income.amount_cents)
        .setIn(['income', 'recurrence'], income.recurrence)
    }

    default:
      return state
  }
}
