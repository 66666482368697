import React from "react";

class ItemValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      internalValue: this.props.value,
    };

    this.isEditing = this.isEditing.bind(this);
    this.isntEditing = this.isntEditing.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  isEditing() {
    this.setState({
      isEditing: true,
      // Below refreshes internal value before editing.
      // It gets stale when editing other cells in the same row.
      // There's probably a better way to do this when editing that other cell.
      internalValue: this.props.value,
    })
  }

  isntEditing() {
    this.setState({isEditing: false})
  }

  updateItem(event) {
    const newAmountDollars = event.target.value;
    const regex_thing = /^\d*\.?\d*$/

    if (!newAmountDollars.match(regex_thing)) {
      return
    }

    this.setState({
      internalValue: newAmountDollars,
    });

    const newValueCents = newAmountDollars * 100;
    this.props.updateItem(this.props.attribute, newValueCents)
  }

  render() {
    let displayValue;

    if (this.state.isEditing) {
      displayValue = this.state.internalValue
    } else {
      displayValue = this.props.value
    }

    return (
      <div className="field">
        <div className="control">
          <input
            className="input is-small has-text-right"
            aria-label={`line-item-${this.props.attribute}-input`}
            type="text"
            onFocus={this.isEditing}
            onBlur={this.isntEditing}
            value={displayValue}
            onChange={(e) => { this.updateItem(e) }}
          />
        </div>
      </div>
    )
  }
}

export default ItemValue;