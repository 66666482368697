import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import SignIn from "./modules/signin/Component";
import SignUp from "./modules/signup/Component";

function UnauthenticatedApp() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />

          <Redirect to="/signin" />
        </Switch>
      </div>
    </Router>
  )
}

export default UnauthenticatedApp;