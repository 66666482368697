import { AUTH_TOKEN } from '../context/auth';

function signIn(email, password) {
  return fetch(
    `${process.env.REACT_APP_HOST}/sign_in`,
    {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password
      })
    })
    .then(response => {
      return response.json()
    })
    .then(json => {
      return json
    })
}

function signUp(email, password) {
  return fetch(
    `${process.env.REACT_APP_HOST}/sign_up`,
    {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password
      })
    })
    .then(response => {
      return response.json()
    })
    .then(json => {
      return json
    })
}

function signOut() {
  const token = window.localStorage.getItem(AUTH_TOKEN)

  return fetch(
    `${process.env.REACT_APP_HOST}/sign_out`,
    {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Authorization': `Token ${token}`, // Unauth'd should 401
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({})
    })
    .then(response => {
      return response.json()
    })
    .then(json => {
      window.localStorage.removeItem(AUTH_TOKEN)
      return json
    })
}


export { signIn, signUp, signOut }