import { Map, fromJS } from 'immutable'

import {
  RECEIVE_BUDGET,
  RECEIVE_NEW_ITEM,
  RECEIVE_UPDATED_ITEM,
  REMOVE_DELETED_ITEM,
  RECEIVE_NEW_GROUP,
  REMOVE_DELETED_GROUP,
  RECEIVE_UPDATED_GROUP,
  RECEIVE_REORDERED_GROUPS,
  RECEIVE_REORDERED_LINE_ITEMS,
} from './actions'

const initialState = Map({
  isLoading: true,
  id: null,
  groups: {},
  settings: {},
});

const camelcaseKeys = require('camelcase-keys')

// TODO can make many of these more performant by using withMutations

export default function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_BUDGET: {
      const budget = camelcaseKeys(action.budgetResponse.budget)
      const settings = camelcaseKeys(action.budgetResponse.settings)
      const income = camelcaseKeys(action.budgetResponse.income)

      // Don't camelcase ID keys.
      // Can maybe use exclude option
      const lineItems = Object.values(action.budgetResponse.line_items).reduce((items, item) => {
        items[item.id] = camelcaseKeys(item)

        return items
      }, {})

      const groups = Object.values(action.budgetResponse.groups).reduce((groups, group) => {
        groups[group.id] = camelcaseKeys(group)

        return groups
      }, {})

      return state
        .set('id', budget.id)
        .set('groupIds', fromJS(budget.groupIds))
        .set('groups', fromJS(groups))
        .set('lineItems', fromJS(lineItems))
        .set('settings', fromJS(settings))
        .set('income', fromJS(income))
        .set('isLoading', false)
    }

    case RECEIVE_UPDATED_ITEM: {
      const item = camelcaseKeys(action.item)

      return state
        .setIn(['lineItems', item.id], fromJS(item))
    }

    case REMOVE_DELETED_ITEM: {
      var newState = state
        .deleteIn(['lineItems', action.itemID])
        .setIn(
          ['groups', action.groupID, 'lineItemIds'],
          state.getIn(['groups', action.groupID, 'lineItemIds']).filter(id => id !== action.itemID)
        );


      if (action.parentId) {
        newState = newState.setIn(
          ['lineItems', action.parentId, 'subLineItemIds'],
          state.getIn(['lineItems', action.parentId, 'subLineItemIds']).filter(id => id !== action.itemID)
        )
      }

      return newState
    }

    case RECEIVE_NEW_ITEM: {
      const item = camelcaseKeys(action.item)

      var newState = state
        .setIn(['lineItems', item.id], fromJS(item))

      if (action.parentId) {
        newState = newState.updateIn(
          ['lineItems', action.parentId, 'subLineItemIds'], list => list.push(item.id)
        )
      } else {
        newState = newState.updateIn(
          ['groups', item.groupId, 'lineItemIds'], list => list.push(item.id)
        )
      }

      return newState
    }

    case RECEIVE_NEW_GROUP: {
      const group = camelcaseKeys(action.group)

      return state
        .update('groupIds', list => list.push(group.id))
        .setIn(['groups', group.id], fromJS(group))
    }

    case REMOVE_DELETED_GROUP: {
      const relatedItemIds = state.getIn(['groups', action.groupID, 'lineItemIds'])
      var newState = state

      relatedItemIds.forEach((id) => {
        newState = newState.deleteIn(['lineItems', id])
      })

      return newState
        .deleteIn(['groups', action.groupID])
        .setIn(
          ['groupIds'],
          state.getIn(['groupIds']).filter(id => id !== action.groupID)
        )
    }

    case RECEIVE_UPDATED_GROUP: {
      const group = camelcaseKeys(action.group)

      return state
        .setIn(['groups', group.id], fromJS(group))
    }

    case RECEIVE_REORDERED_GROUPS: {
      const orderedGroupIDs = action.orderedGroupIDs
      var newState = state

      orderedGroupIDs.forEach((id, index) => {
        newState = newState.setIn(['groups', id, 'sortIndex'], index)
      })

      return newState.set('groupIds', orderedGroupIDs)
    }

    case RECEIVE_REORDERED_LINE_ITEMS: {
      const orderedItemIDs = action.orderedItemIDs
      const groupID = action.groupID
      var newState = state

      orderedItemIDs.forEach((id, index) => {
        newState = newState.setIn(['lineItems', id, 'sortIndex'], index)
      })

      return newState.setIn(['groups', groupID, 'lineItemIds'], orderedItemIDs)
    }

    default:
      return state
  }
}
