import {
  combineReducers
} from 'redux-immutable';
import budget from "./modules/budget/reducer";
import account from "./modules/account/reducer";

export default combineReducers({
  account,
  budget,
});
