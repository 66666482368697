import React from "react";
import onClickOutside from "react-onclickoutside";

class LineItemMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleClickOutside = evt => {
    this.setState({
      isOpen: false
    })
  };

  render () {
    return (
      <div>
        <div className={"dropdown is-right" + (this.state.isOpen ? " is-active" : "")}>
          <div className="dropdown-trigger">
              <span
                className="icon has-text-primary is-clickable pt-1"
                aria-label="line-item-dropdown"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                onClick={() => this.toggle()}
              >
                <i className="fas fa-bars"></i>
              </span>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              {
                this.props.forecastType === "recurring" &&
                !this.props.hasChildren &&
                <a className="dropdown-item" onClick={this.props.switchType}>
                  <span className="icon has-text-grey-light">
                    <i className="far fa-clock"></i>
                  </span>

                  Switch to Target Date
                </a>
              }

              {
                this.props.forecastType === "target_date" &&
                !this.props.hasChildren &&
                <a className="dropdown-item" onClick={this.props.switchType}>
                  <span className="icon has-text-grey-light">
                    <i className="fas fa-sync-alt"></i>
                  </span>

                  Switch to Recurring
                </a>
              }

              {
                !this.props.isChild&&
                <a className="dropdown-item" onClick={this.props.addItem}>
                  <span className="icon has-text-grey-light">
                    <i className="fas fa-plus"></i>
                  </span>

                  Add Sub Line Item
                </a>
              }

              <hr className="dropdown-divider" />

              <a className="dropdown-item" onClick={this.props.removeItem}>
                <span className="icon has-text-grey-light">
                  <i className="far fa-trash-alt"></i>
                </span>

                Remove Item
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default onClickOutside(LineItemMenu);
