import { AUTH_TOKEN } from '../../context/auth';

export const RECEIVE_BUDGET = 'RECEIVE_BUDGET'
export const RECEIVE_UPDATED_ITEM = 'RECEIVE_UPDATED_ITEM'
export const RECEIVE_NEW_ITEM = 'RECEIVE_NEW_ITEM'
export const REMOVE_DELETED_ITEM = 'REMOVE_DELETED_ITEM'
export const RECEIVE_NEW_GROUP = 'RECEIVE_NEW_GROUP'
export const REMOVE_DELETED_GROUP = 'REMOVE_DELETED_GROUP'
export const RECEIVE_UPDATED_GROUP = 'RECEIVE_UPDATED_GROUP'
export const RECEIVE_REORDERED_GROUPS = 'RECEIVE_REORDERED_GROUPS'
export const RECEIVE_REORDERED_LINE_ITEMS = 'RECEIVE_REORDERED_LINE_ITEMS'


// TODO extract all this stuff into an API Client

export function receiveBudget(budgetResponse) {
  return {
    type: RECEIVE_BUDGET,
    budgetResponse
  }
};

export function fetchBudget() {
  return dispatch => {
    const token = window.localStorage.getItem(AUTH_TOKEN)

    return fetch(
      `${process.env.REACT_APP_HOST}/budget`,
      {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (response.status === 401) {
          window.localStorage.removeItem(AUTH_TOKEN)
          window.location.assign(window.location)
        }

        return response.json()
      })
      .then(json => {
        dispatch(receiveBudget(json))
      }).catch(error => {
        console.log(error)
      })
  }
};

export function updateItem(id, name, amountCents, forecastValue, balanceCents) {
  return dispatch => {
    const token = window.localStorage.getItem(AUTH_TOKEN)

    return fetch(
      `${process.env.REACT_APP_HOST}/line_items/${id}`,
      {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          amount_cents: amountCents,
          forecast_value: forecastValue,
          balance_cents: balanceCents,
        })
      })
      .then(response => {
        // TODO figure out how to throw better errors, with response data
        if (response.status === 400) {
          throw new Error()
        }

        return response.json()
      })
      .then(json => {
        dispatch(receiveUpdatedItem(json))
      }).catch(error => {
      })
  }
};


export function createItem(groupID, parentId, sortIndex) {
  return dispatch => {
    const token = window.localStorage.getItem("__token__")

    return fetch(
      `${process.env.REACT_APP_HOST}/line_items`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          group_id: groupID,
          name: "",
          amount_cents: 0,
          forecast_value: "monthly",
          forecast_type: "recurring",
          sort_index: sortIndex,
          parent_line_item_id: parentId,
        })
      })
      .then(response => {
        return response.json()
      })
      .then(json => {
        dispatch(receiveNewItem(groupID, parentId, json))
      })
  }
};

export function removeItem(groupID, itemID, parentId) {
  return dispatch => {
    const token = window.localStorage.getItem("__token__")
    return fetch(
      `${process.env.REACT_APP_HOST}/line_items/${itemID}`,
      {
        method: "DELETE",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        return response.json()
      })
      .then(json => {
        dispatch(removeDeletedItem(groupID, itemID, parentId))
      })
  }
};

export function receiveNewItem(groupID, parentId, item) {
  return {
    type: RECEIVE_NEW_ITEM,
    groupID,
    parentId,
    item,
  }
}

export function receiveUpdatedItem(item) {
  return {
    type: RECEIVE_UPDATED_ITEM,
    item
  }
}

export function removeDeletedItem(groupID, itemID, parentId) {
  return {
    type: REMOVE_DELETED_ITEM,
    groupID,
    itemID,
    parentId,
  }
}

export function createGroup(budgetID, sortIndex) {
  return dispatch => {
    const token = window.localStorage.getItem("__token__")

    return fetch(
      `${process.env.REACT_APP_HOST}/groups`,
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          budget_id: budgetID,
          name: "",
          sort_index: sortIndex,
        })
      })
      .then(response => {
        return response.json()
      })
      .then(json => {
        dispatch(receiveNewGroup(json))
      })
  }
};

export function receiveNewGroup(group) {
  return {
    type: RECEIVE_NEW_GROUP,
    group,
  }
}

export function removeDeletedGroup(groupID) {
  return {
    type: REMOVE_DELETED_GROUP,
    groupID,
  }
}

export function removeGroup(groupID) {
  return dispatch => {
    const token = window.localStorage.getItem("__token__")

    return fetch(
      `${process.env.REACT_APP_HOST}/groups/${groupID}`,
      {
        method: "DELETE",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        return response.json()
      })
      .then(json => {
        dispatch(removeDeletedGroup(groupID))
      })
  }
};

export function updateGroup(id, name) {
  return dispatch => {
    const token = window.localStorage.getItem("__token__")

    return fetch(
      `${process.env.REACT_APP_HOST}/groups/${id}`,
      {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
        })
      })
      .then(response => {
        return response.json()
      })
      .then(json => {
        dispatch(receiveUpdatedGroup(json))
      })
  }
};

export function receiveUpdatedGroup(group) {
  return {
    type: RECEIVE_UPDATED_GROUP,
    group
  }
}

export function receiveReorderdLineItems(groupID, orderedItemIDs) {
  return {
    type: RECEIVE_REORDERED_LINE_ITEMS,
    groupID,
    orderedItemIDs
  }
};

export function reorderItems(groupID, orderedItemIDs) {
  return dispatch => {
    const token = window.localStorage.getItem("__token__")

    return fetch(
      `${process.env.REACT_APP_HOST}/line_items/reorder`, // Does this make more sense as a group resource?
      {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          group_id: groupID,
          line_item_ids: orderedItemIDs
        })
      })
      .then(response => {
        return response.json()
      })
      .then(json => {
        dispatch(receiveReorderdLineItems(groupID, orderedItemIDs))
      })
  }
};

export function receiveReorderdGroups(orderedGroupIDs) {
  return {
    type: RECEIVE_REORDERED_GROUPS,
    orderedGroupIDs
  }
};

export function reorderGroups(budgetID, orderedGroupIDs) {
  return dispatch => {
    const token = window.localStorage.getItem("__token__")

    return fetch(
      `${process.env.REACT_APP_HOST}/groups/reorder`,
      {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          budget_id: budgetID,
          group_ids: orderedGroupIDs
        })
      })
      .then(response => {
        return response.json()
      })
      .then(json => {
        dispatch(receiveReorderdGroups(orderedGroupIDs))
      })
  }
};
