import React from "react";

import debounce from 'lodash/debounce';

import logo from '../logo.png';

import { List } from 'immutable'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import {
  itemDailyValueCents,
} from './util'

import Group from './components/Group';
import BudgetFooter from './components/BudgetFooter';

import {
  createItem,
  fetchBudget,
  removeItem,
  createGroup,
  updateGroup,
  updateItem,
  removeGroup,
  reorderItems,
  reorderGroups,
} from './actions';

function Budget(props) {
  const [groupIds, setGroupIds] = React.useState([])

  React.useEffect(() => {
    props.fetchBudget()
  }, [])

  React.useEffect(() => {
    setGroupIds(props.groupIds)
  }, [props.groupIds])

  function removeGroup(removedGroupId) {
    const newGroupIds = groupIds.filterNot(id => id === removedGroupId)
    setGroupIds(newGroupIds)

    props.removeGroup(
      removedGroupId
    )
  }

  function createGroup() {
    // TODO inefficient. It's for finding the actual last sort index in case you've deleted something
    // Can likely just take the last one, find it's sort idnex and add one.
    const groups = props.groups.valueSeq().toArray()
    const sortIndexes = groups.map(lineItem => lineItem.get('sortIndex'))
    var nextSortIndex = 0

    if (sortIndexes.length !== 0) {
      nextSortIndex = Math.max(...sortIndexes) + 1
    }

    props.createGroup(
      props.id,
      nextSortIndex
    )
  }

  function onDragEnd(result) {
    const source = result.source
    const destination = result.destination

    const sourceIndex = source.index
    const destinationIndex = destination.index

    if (
      destination.droppableId === source.droppableId &&
      destinationIndex === sourceIndex
    ) {
      return
    }

    const movedGroupID = groupIds.get(sourceIndex)

    var newGroupIDs = groupIds.remove(sourceIndex);
    newGroupIDs = newGroupIDs.insert(destinationIndex, movedGroupID);

    setGroupIds(newGroupIDs)

    props.reorderGroups(
      props.id,
      newGroupIDs,
    )
  }

  if (props.isLoading) {
    return <h1>LOADING...</h1>
  };

  return (
    <div>
      <div className="container">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <Link to="/budget">
                <img className="logo" src={logo} />
              </Link>
            </div>

            <div className="level-right">
              <Link to="/account">
                <span className="icon has-text-primary">
                  <i className="fas fa-user-alt"></i>
                </span>
              </Link>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {groupIds.map((id, index) => {
                    const group = props.groups.get(id)

                    return (
                      <Draggable key={group.get('id')} draggableId={group.get('id')} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="box"
                          >
                            <Group
                              key={group.get('id')}
                              dragHandleProps={provided.dragHandleProps}
                              budgetID={props.id}
                              id={group.get('id')}
                              name={group.get('name')}
                              lineItems={props.lineItems}
                              lineItemIDs={group.get('lineItemIds')}
                              sortIndex={group.get('sortIndex')}
                              totals={props.totals['groups'][group.get('id')]}

                              createGroup={debounce(createGroup, 100)}
                              updateGroup={debounce(props.updateGroup, 500)}
                              removeGroup={debounce(removeGroup, 100)}

                              createItem={debounce(props.createItem, 100)}
                              updateItem={props.updateItem}
                              removeItem={props.removeItem}
                              reorderItems={debounce(props.reorderItems, 100)}

                              incomeRecurrence={props.income.get('recurrence')}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <BudgetFooter
          dailyValueCents={props.totals["budget"]["dailyValueCents"]}
          balanceCents={props.totals["budget"]["balanceCents"]}
          incomeAmount={props.income.get('amountCents')}
          incomeRecurrence={props.income.get('recurrence')}
        />

        <div className="level">
          <button className="button is-small is-primary is-light" onClick={createGroup}>
            Add Group
          </button>
        </div>

        <hr></hr>

        <p className="is-italic">Questions? Comments? Feedback? Email us: <a href="mailto:hello@sunday.money">hello@sunday.money</a></p>

        <div className="level"></div>
        <div className="level"></div>

      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const budgetState = state.get('budget');
  const id = budgetState.get('id');
  const isLoading = budgetState.get('isLoading');

  if (isLoading) {
    return {
      id,
      isLoading,
      groupIds: List(),
    }
  }

  const groupIds = budgetState.get('groupIds')
  const groups = budgetState.get('groups')
  const lineItems = budgetState.get('lineItems')
  const income = budgetState.get('income')
  const settings = budgetState.get('settings')


  const totals = calculateTotals(groups, lineItems, income);

  return {
    isLoading,
    id,
    groupIds,
    groups,
    lineItems,
    totals,
    settings,
    income,
  }
}

Budget.defaultProps = {
  income: {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createItem,
    fetchBudget,
    removeItem,
    createGroup,
    updateGroup,
    updateItem,
    removeGroup,
    reorderItems,
    reorderGroups,
  }, dispatch);
}

function calculateTotals(groups, lineItems, income) {
  // TODO is there a better way to track this, deeper in the component stack
  const totals = {
    "budget": {
      "dailyValueCents": 0,
      "balanceCents": 0,
    },
    "groups": {},
    "lineItems": {},
  };

  // Instantiate for use below, and also in the case groups don't have items.
  groups.valueSeq().forEach(group => {
    totals["groups"][group.get('id')] = {
      "dailyValueCents": 0,
      "balanceCents": 0,
    }
  });

  lineItems.valueSeq().forEach(item => {
    totals["lineItems"][item.get('id')] = totals["lineItems"][item.get('id')] || {
      "dailyValueCents": 0,
      "balanceCents": 0,
    }

    const subLineItems = item.get('subLineItemIds').map((id) => {
      return lineItems.get(id)
    })

    const balanceCents = item.get('balanceCents')
    const dailyValueCents = itemDailyValueCents(
      item.get('forecastType'),
      item.get('forecastValue'),
      item.get('amountCents'),
      item.get('balanceCents'),
      item.get('paychecksUntilTargetDate'),
      income.get('recurrence'),
      subLineItems,
      item.get('parentLineItemId')
    )

    totals["budget"]["balanceCents"] += balanceCents
    totals["budget"]["dailyValueCents"] += dailyValueCents

    totals["groups"][item.get('groupId')]["balanceCents"] += balanceCents
    totals["groups"][item.get('groupId')]["dailyValueCents"] += dailyValueCents;

    totals["lineItems"][item.get('id')]["balanceCents"] += balanceCents;
    totals["lineItems"][item.get('id')]["dailyValueCents"] += dailyValueCents;
  });

  return totals;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Budget)
