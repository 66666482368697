import React from "react";

import debounce from 'lodash/debounce';

import logo from '../logo.png';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import {
  fetchAccount,
  updateSettings,
  updateIncome,
} from './actions';

import { useAuth } from "../.././context/auth";

function Account(props) {
  const { signOut } = useAuth()

  const [incomeAmount, setIncomeAmount] = React.useState("")
  const [incomeRecurrence, setIncomeRecurrence] = React.useState("")
  const [incomeStartDate, setIncomeStartDate] = React.useState("")

  React.useEffect(() => {
    props.fetchAccount()
  }, [])

  function updateIncomeAmount(amountDollars) {
    const regex_thing = /^\d*\.?\d*$/

    if (!amountDollars.match(regex_thing)) {
      return
    }

    setIncomeAmount(amountDollars)

    const amountCents = amountDollars * 100;
    props.updateIncome(amountCents, incomeRecurrence, incomeStartDate)
  }

  function updateIncomeRecurrence(recurrence) {
    setIncomeRecurrence(recurrence)

    const amountCents = incomeAmount * 100; // TODO maybe store in cents
    props.updateIncome(amountCents, recurrence, incomeStartDate)
  }

  function updateIncomeStartDate(date) {
    setIncomeStartDate(date)

    const amountCents = incomeAmount * 100; // TODO maybe store in cents
    props.updateIncome(amountCents, incomeRecurrence, date)
  }

  React.useEffect(() => {
    if (props.income) { //  TODO default props
      const amountDollars = props.income.amountCents / 100

      setIncomeAmount(amountDollars || "")
      setIncomeRecurrence(props.income.recurrence || "")
      setIncomeStartDate(props.income.startDate || "")
    }
  }, [props.income])

  return (
    <div>
      <div className="container">
        <div className="box">
          <div className="level">
            <div className="level-left">
              <Link to="/budget">
                <img alt="logo" className="logo" src={logo} />
              </Link>
            </div>

            <div className="level-right">
              <Link to="/account">
                <span className="icon has-text-grey-light">
                  <i className="fas fa-user-alt"></i>
                </span>
              </Link>
            </div>
          </div>
        </div>

        <div className="level">
          <h1 className="title">Account</h1>
        </div>

        <h2 className="subtitle">Details</h2>

        <p>{props.email}</p>

        <hr></hr>

        <h2 className="subtitle">Income</h2>

        My take home pay is
        <div className="field has-addons">
          <div className="control has-icons-left">
            <input
              className="input has-text-right"
              aria-label={`income-input`}
              type="text"
              value={incomeAmount}
              onChange={(e) => { updateIncomeAmount(e.target.value) }}
            />

            <span className="icon is-small is-left">
              <i className="fas fa-dollar-sign"></i>
            </span>
          </div>

          <div className="control">
            <div className="select">
              <select
                className="input select"
                aria-label={'income-input'}
                value={incomeRecurrence}
                onChange={(e) => { updateIncomeRecurrence(e.target.value) }}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="bi_weekly">Bi-weekly</option>
                <option value="first_and_fifteenth">First & 15th</option>
                <option value="fifteenth_and_last">15th & Last</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          </div>
        </div>

        Starting on
        <div className="field has-addons">
          <div className="control">
            {/* TODO react-datepicker */}
            <input
              className="input"
              aria-label={`income-date-input`}
              type="date"
              value={incomeStartDate}
              onChange={(e) => { updateIncomeStartDate(e.target.value) }}
            />
          </div>
        </div>

        <hr></hr>

        <div className="level">
          <Link to="/budget">
            <button className="button is-small is-primary is-light">
              Back
            </button>
          </Link>

          <button className="button is-small is-primary is-light" onClick={signOut}>
            Sign out
          </button>
        </div>
      </div>
    </div>
  )
}



const mapStateToProps = (state) => {
  const accountState = state.get('account')
  const email = accountState.get('email')
  const settings = accountState.get('settings')
  const income = accountState.get('income');

  return {
    email,
    settings,
    income,
  }
}

const mapDispatchToProps = (dispatch) => {
  // const debouncedUpdateIncome = debounce(updateIncome, 500)

  return bindActionCreators({
    fetchAccount,
    updateSettings,
    updateIncome,
    }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account)