export const DAYSINWEEK = 7
export const DAYSINTWOWEEKS = 14
export const DAYSINSEMIMONTH = 15.2184375
export const DAYSINMONTH = 30.436875
export const DAYSINYEAR = 365.2425

export function forecastToDaily(amountCents, forecastValue) {
  return amountCents / conversionValue(forecastValue);
}

export function dailyToForecast(amountCents, forecastValue) {
  return amountCents * conversionValue(forecastValue);
}

export function conversionValue(forecastValue) {
  switch (forecastValue) {
    case "daily":
      return 1

    case "weekly":
      return DAYSINWEEK

    case "bi-weekly":
    case "bi_weekly":
      return DAYSINTWOWEEKS

    case "semi-monthly":
    case "semi_monthly":
    case "first_and_fifteenth":
    case "fifteenth_and_last":
      return DAYSINSEMIMONTH

    case "monthly":
      return DAYSINMONTH

    case "yearly":
      return DAYSINYEAR

    default:
      return 1;
  }
}

export function targetPaycheckValue(amountCents, balanceCents, paychecksRemaining) {
  // Can be either dollars or cents
    if (paychecksRemaining === 0) {
      return 0
    }
    const remainingBalance = amountCents - balanceCents

    if (remainingBalance < 0) {
      return 0
    }

    return remainingBalance / paychecksRemaining;
}

export function recurringPaycheckValue(dailyValueCents, recurrence) {
  const lookup = {
    "daily": {
      "value": dailyDollars(dailyValueCents),
      "attribute": "daily",
    },
    "weekly": {
      "value": weeklyDollars(dailyValueCents),
      "attribute": "weekly",
    },
    "bi_weekly": {
      "value": biWeeklyDollars(dailyValueCents),
      "attribute": "bi_weekly",
    },
    "first_and_fifteenth": {
      "value": semiMonthlyDollars(dailyValueCents),
      "attribute": "semi_monthly",
    },
    "fifteenth_and_last": {
      "value": semiMonthlyDollars(dailyValueCents),
      "attribute": "semi_monthly",
    },
    "monthly": {
      "value": monthlyDollars(dailyValueCents),
      "attribute": "monthly",
    },
  }

  return lookup[recurrence]
}

export function dailyCentsToForecastDollars(dailyValueCents, recurrence) {
  const amountCents = dailyToForecast(dailyValueCents, recurrence)

  return centsToDollars(amountCents)
}

export function centsToDollars(cents) {
  return (cents / 100)
}

export function dailyDollars(dailyValueCents) {
  return (dailyValueCents / 100).toFixed(2)
}

export function weeklyDollars(dailyValueCents) {
  return ((dailyValueCents * DAYSINWEEK) / 100).toFixed(2)
}

export function biWeeklyDollars(dailyValueCents) {
  return ((dailyValueCents * DAYSINTWOWEEKS) / 100).toFixed(2)
}

export function semiMonthlyDollars(dailyValueCents) {
  return ((dailyValueCents * DAYSINSEMIMONTH) / 100).toFixed(2)
}

export function monthlyDollars(dailyValueCents) {
  return ((dailyValueCents * DAYSINMONTH) / 100).toFixed(2)
}

export function yearlyDollars(dailyValueCents) {
  return ((dailyValueCents * DAYSINYEAR) / 100).toFixed(2)
}

export function formatCurrency(amountDollars) {
  return amountDollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export function itemDailyValueCents(forecastType, forecastValue, amountCents, balanceCents, paychecksUntilTargetDate, incomeRecurrence, children, parentId) {
  if (parentId) {
    return 0 // children don't get counted invidiually -- rolled up into parent.
  }

  const hasChildren = children.size > 0

  if (!hasChildren) {
    if (forecastType === "recurring") {
      return forecastToDaily(amountCents, forecastValue);
    } else {
      const perPaycheckCents = targetPaycheckValue(amountCents, balanceCents, paychecksUntilTargetDate);
      return forecastToDaily(perPaycheckCents, incomeRecurrence);
    }
  }

  var remainingBalance = balanceCents
  var total = 0

  children.forEach((item) => {
    if (item.get('forecastType') === "recurring") {
      const dailyValueCents = forecastToDaily(item.get('amountCents'), item.get('forecastValue'));
      total += dailyValueCents
    }

    if (item.get('forecastType') === "target_date") {
      var balance
      const amount = item.get('amountCents')

      if (remainingBalance >= amount) {
        balance = amount
      } else {
        balance = remainingBalance // TODO what about 0?
      }

      const perPaycheckCents = targetPaycheckValue(
        amount,
        balance,
        item.get('paychecksUntilTargetDate'),
      )
      const dailyValueCents = forecastToDaily(perPaycheckCents, incomeRecurrence)

      total += dailyValueCents
      remainingBalance -= balance
    }
  })

  return total
}

// parentPaycheckValue() {
//   var remainingBalance = this.state.balanceCents
//   var total = 0

//   this.props.subLineItems.forEach((item) => {
//     if (item.get('forecastType') === "recurring") {
//       const dailyValueCents = forecastToDaily(item.get('amountCents'), item.get('forecastValue'))
//       total += dailyToForecast(dailyValueCents, this.props.incomeRecurrence)
//     }

//     if (item.get('forecastType') === "target_date") {
//       // TODO maybe wanna prioritize closer ones? Or whateaver optimization is best.
//       var balance
//       const amount = item.get('amountCents')

//       if (remainingBalance >= amount) {
//         balance = amount
//       } else {
//         balance = remainingBalance // TODO what about 0?
//       }

//       total += targetPaycheckValue(
//         amount,
//         balance,
//         item.get('paychecksUntilTargetDate'),
//       )
//       debugger
//       remainingBalance -= balance
//     }
//   })

//   debugger
//   return total
// }