import React from "react";
import debounce from 'lodash/debounce';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  dailyCentsToForecastDollars,
  formatCurrency,
} from '../util'

import LineItem from './LineItem';

class Group extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
      budgetID: props.budgetID,
      lineItemIDs: props.lineItemIDs,
    };

    this.createItem = this.createItem.bind(this);
    this.removeItem = this.removeItem.bind(this);

    this.updateName = this.updateName.bind(this);
    this.removeGroup = this.removeGroup.bind(this);

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  createItem(parentId) {
    if (parentId) {
      this.props.createItem(
        this.props.id,
        parentId,
        0 // TODO
      )
    } else {
      // TODO this is wrong we get all items
      const items = this.props.lineItems.valueSeq().toArray()
      const sortIndexes = items.map(lineItem => lineItem.get('sortIndex'))
      var nextSortIndex = 0

      if (sortIndexes.length !== 0) {
        nextSortIndex = Math.max(...sortIndexes) + 1
      }

      this.props.createItem(
        this.props.id,
        null, // parentId
        nextSortIndex
      )
    }
  }

  componentDidUpdate(prevProps) {
    // Adding an item
    if (this.props.lineItemIDs.size > prevProps.lineItemIDs.size) {
      this.setState({
        lineItemIDs: this.props.lineItemIDs,
      })
    }
  }

  removeItem(deletedItemID, parentId) {
    const lineItemIds = this.state.lineItemIDs.filterNot(id => id === deletedItemID)

    this.setState({
      lineItemIDs: lineItemIds,
    })

    this.props.removeItem(
      this.props.id,
      deletedItemID,
      parentId
    )
  }

  updateName(event) {
    const newName = event.target.value

    this.setState({
      name: newName,
    });

    this.props.updateGroup(
      this.props.id,
      newName,
    )
  }

  removeGroup() {
    this.props.removeGroup(
      this.props.id,
    )
  }

  onDragEnd(result) {
    const source = result.source
    const destination = result.destination

    if (!destination) {
      return
    }

    const sourceIndex = source.index
    const destinationIndex = destination.index

    if (
      destination.droppableId === source.droppableId &&
      destinationIndex === sourceIndex
    ) {
      return
    }

    const movedItemID = this.state.lineItemIDs.get(sourceIndex)

    var newLineItemIDs = this.state.lineItemIDs.remove(sourceIndex);
    newLineItemIDs = newLineItemIDs.insert(destinationIndex, movedItemID);

    this.setState({
      lineItemIDs: newLineItemIDs,
    })

    this.props.reorderItems(
      this.props.id,
      newLineItemIDs,
    )
  }

  render() {
    const lineItems = this.state.lineItemIDs.map((id, index) => {
      const item = this.props.lineItems.get(id)

      const subLineItems = item.get('subLineItemIds').map((id) => {
        return this.props.lineItems.get(id)
      })

      return (
        <Draggable key={item.get('id')} draggableId={item.get('id')} index={index}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <LineItem
                dragHandleProps={provided.dragHandleProps}
                key={item.get('id')}
                item={item}
                subLineItems={subLineItems}
                incomeRecurrence={this.props.incomeRecurrence}
                isChild={false}

                addItem={this.createItem} // TODO debounce
                updateItem={
                  debounce(this.props.updateItem, 500)
                }
                removeItem={
                  debounce(this.removeItem, 100) // TODO this ain't snappy
                }
              />
            </div>
          )}
        </Draggable>
      )
    })

    return (
      <>
        <div className="columns is-gapless mb-3">
          <div className="column is-narrow">
            <span className="icon has-text-primary pt-1" {...this.props.dragHandleProps} >
              <i className="fas fa-grip-vertical"></i>
            </span>
          </div>

          <div className="column is-3 mr-3">
            <input
              className="input is-small"
              aria-label="group-name-input"
              type="text"
              value={this.state.name}
              onChange={(e) => { this.updateName(e) }}
            />
          </div>

          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Target Date</div>
          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Amount</div>

          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Yearly</div>
          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Monthly</div>
          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Weekly</div>
          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Daily</div>

          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px ml-3">Paycheck</div>

          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px ml-3">Balance</div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-narrow">
            <span
              className="icon has-text-primary is-clickable"
              aria-label="delete-group"
              onClick={this.removeGroup}
            >
              <i className="far fa-trash-alt"></i>
            </span>
          </div>
        </div>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {lineItems}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="columns is-gapless mt-3">
          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-3 mr-3">
            <>
              <button
                className="button is-primary is-light is-small"
                onClick={(e) => { this.createItem(null) }}
              >
                Add Line Item
              </button>
            </>
          </div>

          <div className="column is-size-7 is-italic has-text-right mr-9px"></div>
          <div className="column is-size-7 is-italic has-text-right mr-9px"></div>

          <div className="column is-size-7 is-italic has-text-right mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.totals["dailyValueCents"], "yearly")
              )
            }
          </div>

          <div className="column is-size-7 is-italic has-text-right mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.totals["dailyValueCents"], "monthly")
              )
            }
          </div>

          <div className="column is-size-7 is-italic has-text-right mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.totals["dailyValueCents"], "weekly")
              )
            }
          </div>

          <div className="column is-size-7 is-italic has-text-right mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.totals["dailyValueCents"], "daily")
              )
            }
          </div>

          <div className="column is-size-7 is-italic has-text-right mr-9px ml-3">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.totals["dailyValueCents"], this.props.incomeRecurrence)
              )
            }
          </div>

          <div className="column is-size-7 is-italic has-text-right mr-9px ml-3">{formatCurrency(this.props.totals["balanceCents"] / 100)}</div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>
        </div>
      </>
    )
  }
}

export default Group;