import { AUTH_TOKEN } from '../../context/auth';

export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT'
export const RECEIVE_UPDATED_SETTINGS = 'RECEIVE_UPDATED_SETTINGS'
export const RECEIVE_UPDATED_INCOME = 'RECEIVE_UPDATED_INCOME'


export function receiveAccount(account) {
  return {
    type: RECEIVE_ACCOUNT,
    account
  }
};

export function receiveUpdatedSettings(settings) {
  return {
    type: RECEIVE_UPDATED_SETTINGS,
    settings
  }
};

export function fetchAccount() {
  return dispatch => {
    const token = window.localStorage.getItem(AUTH_TOKEN)

    return fetch(
      `${process.env.REACT_APP_HOST}/account`,
      {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        if (response.status === 401) {
          window.localStorage.removeItem(AUTH_TOKEN)
          window.location.assign(window.location)
        }

        return response.json()
      })
      .then(json => {
        dispatch(receiveAccount(json))
      }).catch(error => {
      })
  }
};

export function updateSettings(setting, value) {
  return dispatch => {
    const token = window.localStorage.getItem(AUTH_TOKEN)
    const settings = {}
    settings[setting] = value

    return fetch(
      `${process.env.REACT_APP_HOST}/settings`,
      {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(settings)
      })
      .then(response => {
        if (response.status === 401) {
          window.localStorage.removeItem(AUTH_TOKEN)
          window.location.assign(window.location)
        }

        return response.json()
      })
      .then(json => {
        dispatch(receiveUpdatedSettings(json))
      }).catch(error => {
      })
  }
};

export function receiveUpdatedIncome(income) {
  return {
    type: RECEIVE_UPDATED_INCOME,
    income
  }
};

export function updateIncome(amountCents, recurrence, startDate) {
  return dispatch => {
    const token = window.localStorage.getItem(AUTH_TOKEN)

    return fetch(
      `${process.env.REACT_APP_HOST}/income`,
      {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount_cents: amountCents,
          recurrence,
          start_date: startDate,
        })
      })
      .then(response => {
        if (response.status === 401) {
          window.localStorage.removeItem(AUTH_TOKEN)
          window.location.assign(window.location)
        }

        return response.json()
      })
      .then(json => {
        dispatch(receiveUpdatedIncome(json))
      }).catch(error => {
      })
  }
};
