import React from 'react'

import {
  formatCurrency,
  forecastToDaily,
  dailyCentsToForecastDollars,
} from '../util'

class BudgetFooter extends React.Component {
  render() {
    const dailyIncomeCents = forecastToDaily(this.props.incomeAmount, this.props.incomeRecurrence)
    const netAmountCents = dailyIncomeCents - this.props.dailyValueCents
    const potentiallyRed = netAmountCents < 0 ? " has-text-danger" : ""

    return (
      <div className="box">

        {/* Header */}
        <div className="columns is-gapless mb-3">
          <div className="column is-narrow">
            <span className="icon">
            </span>
          </div>

          <div className="column is-3 mr-3">
          </div>

          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px"></div>
          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px"></div>

          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Yearly</div>
          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Monthly</div>
          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Weekly</div>
          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px">Daily</div>

          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px ml-3">Paycheck</div>

          <div className="column is-size-7 has-text-primary has-text-weight-bold has-text-right mr-9px ml-3">Balance</div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>
        </div>

        {/* Income */}
        <div className="columns is-gapless is-size-7 mb-0">
          <div className="column is-narrow">
            <span className="icon has-text-info">
            </span>
          </div>

          <div className="column is-3 mr-3 has-text-weight-bold">
            Income
            </div>

          <div className="column is-italic has-text-right	mr-9px"></div>
          <div className="column is-italic has-text-right	mr-9px"></div>

          <div className="column is-italic has-text-right	mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(dailyIncomeCents, "yearly")
              )
            }
          </div>

          <div className="column is-italic has-text-right	mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(dailyIncomeCents, "monthly")
              )
            }
          </div>

          <div className="column is-italic has-text-right	mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(dailyIncomeCents, "weekly")
              )
            }
          </div>

          <div className="column is-italic has-text-right	mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(dailyIncomeCents, "daily")
              )
            }
          </div>

          <div className="column is-italic has-text-right	mr-9px ml-3">
            {
              formatCurrency(
                dailyCentsToForecastDollars(dailyIncomeCents, this.props.incomeRecurrence)
              )
            }
          </div>

          <div className="column is-italic has-text-right	mr-9px ml-3"></div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>
        </div>

        {/* Budgeted */}
        <div className="columns is-gapless is-size-7 mb-0">
          <div className="column is-narrow">
            <span className="icon has-text-info">
            </span>
          </div>

          <div className="column is-3 mr-3 has-text-weight-bold">
            Budgeted
            </div>

          <div className="column is-italic has-text-right	mr-9px"></div>
          <div className="column is-italic has-text-right	mr-9px"></div>

          <div className="column is-italic has-text-right mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.dailyValueCents, "yearly")
              )
            }
          </div>

          <div className="column is-italic has-text-right mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.dailyValueCents, "monthly")
              )
            }
          </div>

          <div className="column is-italic has-text-right mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.dailyValueCents, "weekly")
              )
            }
          </div>

          <div className="column is-italic has-text-right mr-9px">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.dailyValueCents, "daily")
              )
            }
          </div>

          <div className="column is-italic has-text-right mr-9px ml-3">
            {
              formatCurrency(
                dailyCentsToForecastDollars(this.props.dailyValueCents, this.props.incomeRecurrence)
              )
            }
          </div>

          <div className="column mr-9px ml-3"></div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>
        </div>

        {/* Net */}
        <div className="columns is-gapless is-size-7 pt-3 bt-1">
          <div className="column is-narrow">
            <span className="icon has-text-info">
            </span>
          </div>

          <div className="column is-3 mr-3 has-text-weight-bold">
            Net
            </div>

          <div className="column is-italic has-text-right	mr-9px"></div>
          <div className="column is-italic has-text-right	mr-9px"></div>

          <div className={"column is-italic has-text-weight-bold has-text-right mr-9px" + potentiallyRed}>
            {
              formatCurrency(
                dailyCentsToForecastDollars(netAmountCents, "yearly")
              )
            }
          </div>

          <div className={"column is-italic has-text-weight-bold has-text-right mr-9px" + potentiallyRed}>
            {
              formatCurrency(
                dailyCentsToForecastDollars(netAmountCents, "monthly")
              )
            }
          </div>

          <div className={"column is-italic has-text-weight-bold has-text-right mr-9px" + potentiallyRed}>
            {
              formatCurrency(
                dailyCentsToForecastDollars(netAmountCents, "weekly")
              )
            }
          </div>

          <div className={"column is-italic has-text-weight-bold has-text-right mr-9px" + potentiallyRed}>
            {
              formatCurrency(
                dailyCentsToForecastDollars(netAmountCents, "daily")
              )
            }
          </div>

          <div className={"column is-italic has-text-weight-bold has-text-right mr-9px ml-3" + potentiallyRed}>
            {
              formatCurrency(
                dailyCentsToForecastDollars(netAmountCents, this.props.incomeRecurrence)
              )
            }
          </div>

          <div className="column is-italic has-text-weight-bold has-text-right mr-9px ml-3">
            {formatCurrency(this.props.balanceCents / 100)}
          </div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>

          <div className="column is-narrow">
            <span className="icon"></span>
          </div>
        </div>
      </div>
    )
  }
}

export default BudgetFooter