import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import { compose, createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";

import thunk from 'redux-thunk'

import reducer from "./reducer";
import Budget from "./modules/budget/Component"
import Account from "./modules/account/Component"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(
      thunk,
    ),
  )
);


function AuthenticatedApp() {
  return (
    <Provider store={store}>
      <Router>
        <div>
          <Switch>
            <Route exact path="/budget" component={Budget} />
            <Route exact path="/account" component={Account} />

            <Redirect to="/budget" />
          </Switch>
        </div>
      </Router>
    </Provider>
  )
}

export default AuthenticatedApp;
