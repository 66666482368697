import React from "react";

import {
  Link
} from "react-router-dom";

import { useAuth } from "../.././context/auth";

function SignIn() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { signIn, error } = useAuth()

  function handleSubmit(event) {
    event.preventDefault()

    signIn(email, password)
  }

  return (
    <div className="container pt-6">
      <div className="columns">
        <div className="column">
          <h1 className="title">Sign In</h1>

          {
            error &&
            <div className="notification is-danger is-light">
              {error}
            </div>
          }

          <form onSubmit={handleSubmit}>
            <div className="field">
              <input
                type="email"
                placeholder="Email"
                onChange={e => { setEmail(e.target.value)}}
                className="input"
              />
            </div>

            <div className="field">
              <input
                type="password"
                placeholder="Password"
                onChange={e => {setPassword(e.target.value)}}
                className="input"
              />
            </div>

            <div className="field">
              <button
                className="button is-primary"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="level">
        <p className="is-italic">Don't have an account? <Link to="/signup">Sign Up</Link></p>
      </div>
    </div>
  )
}

export default SignIn;