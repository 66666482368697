import React from 'react'

import { createContext, useContext } from 'react';

import * as authClient from '../utils/auth';

export const AuthContext = createContext();
export const AUTH_TOKEN = '__token__';


function AuthProvider(props) {
  const [token, setToken] = React.useState(localStorage.getItem(AUTH_TOKEN));
  const [error, setError] = React.useState("");

  const signIn = async (email, password) => {
    const json = await authClient.signIn(email, password)

    // TODO better to check status code
    if (json["token"]) {
      window.localStorage.setItem(AUTH_TOKEN, json['token'])
      setToken(json['token'])
      setError("")
    } else if (json["error_message"]) {
      setError(json["error_message"])
    }

  }

  const signUp = async (email, password) => {
    const json = await authClient.signUp(email, password)

    // TODO better to check status code
    if (json["token"]) {
      window.localStorage.setItem(AUTH_TOKEN, json['token'])
      setToken(json['token'])
      setError("")
    } else if (json["error_message"]) {
      setError(json["error_message"])
    }

  }

  const signOut = async () => {
    await authClient.signOut()

    setError("")
    setToken("")
  }

  const value = { token, error, signIn, signUp, signOut }

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { useAuth, AuthProvider }



