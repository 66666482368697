import React from "react";

import AuthenticatedApp from "./AuthenticatedApp"
import UnauthenticatedApp from "./UnauthenticatedApp"
import { useAuth } from "./context/auth";

import '../src/css/styles.css';

function App() {
  const { token } = useAuth();

  return (
    <>
      {token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </>
  );
};

export default App;