import React from "react";

import onClickOutside from "react-onclickoutside";

import ItemValue from './ItemValue';

class Balance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      modificationValue: "0.00",
    }

    this.toggle = this.toggle.bind(this)
    this.updateModificationValue = this.updateModificationValue.bind(this)
    this.localUpdateItem = this.localUpdateItem.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  updateModificationValue(event) {
    const newValue = event.target.value

    // TODO extract. shared with ItemValue
    const regex_thing = /^\d*\.?\d*$/

    if (!newValue.match(regex_thing)) {
      return
    }

    this.setState({
      modificationValue: newValue
    })
  }

  handleClickOutside = event => {
    this.setState({
      isOpen: false,
      modificationValue: "0.00",
    })
  };

  localUpdateItem(event, operation) {
    const existingValue = this.props.value
    const delta = this.state.modificationValue

    let newValue

    if (operation === "add") {
      newValue = parseFloat(existingValue) + parseFloat(delta)
    } else {
      newValue = parseFloat(existingValue) - parseFloat(delta)
    }

    const newValueCents  = newValue * 100

    this.props.updateItem("balance", newValueCents)
  }

  render() {
    return (
      <div>
        <div className={"dropdown is-right" + (this.state.isOpen ? " is-active" : "")}>
          <div className="dropdown-trigger">
            <span
              className=""
              aria-label="balance-dropdown"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => this.toggle()}
            >
              <ItemValue value={this.props.value} attribute={"balance"} updateItem={this.props.updateItem} />
            </span>
          </div>

          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              <div className="dropdown-item">
                <p>Update Balance</p>
                <div className="field">
                  <div className="control">
                    <input
                      className="input is-small has-text-right"
                      aria-label={`balance-modification-input`}
                      type="text"
                      value={this.state.modificationValue}
                      onChange={e => { this.updateModificationValue(e) }}
                    />
                  </div>
                </div>

                <p className="buttons">
                  <button
                    className="button"
                    onClick={e => { this.localUpdateItem(e, "add") }}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-plus"></i>
                    </span>
                  </button>

                  <button
                    className="button" // TODO is-right
                    onClick={e => { this.localUpdateItem(e, "subtract") }}
                  >

                    <span className="icon is-small">
                      <i className="fas fa-minus"></i>
                    </span>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default onClickOutside(Balance);
